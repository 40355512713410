<template>
  <div class="container">
    <div class="row">
      <div
        class="col-12 col-md-6 col-xxl-3 mb-4 mt-2"
        v-for="profileType in profileTypes"
        :key="profileType.value"
      >
        <base-card
          class="h-100"
          @click.native="$emit('profile-type-select', profileType.value)"
          @keyup.native.enter="$emit('profile-type-select', profileType.value)"
          @keyup.native.space="$emit('profile-type-select', profileType.value)"
          role="button"
          tabindex="0"
        >
          <div class="content">
            <icon :icon="profileType.value" width="70" height="70" />
            <h2 class="my-3">{{ profileType.title }}</h2>
            <p class="text-center">{{ profileType.description }}</p>
          </div></base-card
        >
      </div>
      <div class="proceed-btn">
        <default-button
          :labelText="'Next →'"
          @click="$emit('go-to-profile-complete')"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "profile-type-select",
  data() {
    return {
      profileTypes: [
        {
          value: "candidate",
          title: "Job Seeker",
          description:
            "Entry level or skilled worker looking to start or elevate your career in the skilled trades industry.",
        },
        {
          value: "provider",
          title: "Company",
          description:
            "Business or independent contractor that provides services in the construction or skilled trades industry",
        },
      ],
    }
  },
}
</script>

<style lang="scss" scoped>
.container {
  .card {
    border: 2px solid transparent;
    cursor: pointer;
    padding: 2rem;
    transition: border 0.2s linear;

    &:hover {
      border-color: #7cd2ff;
    }

    &:focus,
    :focus-within {
      outline: none;
      border-color: #7cd2ff;
    }
  }

  .proceed-btn {
    text-align: center;
    width: 100%;
  }
}
.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  h2 {
    line-height: 1.5;
  }

  p {
    font-size: 0.875rem;
  }
}
</style>
